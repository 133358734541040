<template>
  <div class='ui reference modal'>
    <div class='header'>{{ dialogVerb }} References</div>
    <div class='scrolling content'>
      <form class='ui form'>
        <component v-bind='referenceData' :identifiers.sync='identifiers' ref='referenceDataComponent' v-if='referenceData.is'></component>
      </form>
    </div>
    <div class='actions'>
      <div class='ui positive button' :class='{ disabled: modal.forceMinimum && identifiers.length === 0 }'>Save</div>
      <div class='ui negative button'>Cancel</div>
    </div>
  </div>
</template>

<script>
  import TeachingCouncilStandards from '../../../components/references/teaching_council_standards.vue'

  export default {
    data() {
      return {
        identifiers: []
      }
    },
    computed: {
      dialogVerb() {
        if (this.currentIdentifiers.length) { return 'Edit' } else { return 'Add' }
      },
      modal() {
        return this.$store.state.documentReferenceModal
      },
      isVisible() {
        return this.modal.isVisible
      },
      referenceData() {
        return this.$store.state.referenceData
      },
      value() {
        return this.$store.state.documentModal.value
      },
      reference() {
        return this.$store.state.references[this.modal.referenceId] || null
      },
      currentIdentifiers() {
        if (this.modal.identifiers) {
          return this.modal.identifiers
        } else if (this.reference) {
          return this.reference.reference_value_ids.map((id) => this.$store.state.values[id].identifier)
        } else {
          return []
        }
      }
    },
    mounted() {
      $(this.$el).modal({
        selector: {
          approve: '.actions .positive:not(.disabled)'
        },
        closable: false,
        allowMultiple: true,
        context: '.submodal.context',
        onShow: () => {
          this.$refs.referenceDataComponent.show()
        },
        onHidden: () => {
          this.$refs.referenceDataComponent.hide()
          this.$store.commit('documentReferenceModal/reset')
        },
        onDeny: () => {
          this.$store.commit('documentReferenceModal/deny')

          return false
        },
        onApprove: () => {
          this.$store.commit('documentReferenceModal/approve', {
            identifiers: this.identifiers
          })

          return false
        }
      })
    },
    watch: {
      isVisible(isVisible) {
        if (isVisible) {
          this.identifiers = this.currentIdentifiers

          this.$nextTick(function() {
            $(this.$el).modal('show')
          })
        } else {
          $(this.$el).modal('hide')
        }
      }
    },
    components: {
      TeachingCouncilStandards
    }
  }
</script>
