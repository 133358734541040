import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]

  toggleMenu() {
    $(this.menuTarget).sidebar('toggle')
  }

  openMenu() {
    $(this.menuTarget).sidebar('show')
  }

  closeMenu() {
    $(this.menuTarget).sidebar('hide')
  }
}
