// Modal Components
import ValueModal from '../documents/values/modals/value.vue'
import DocumentReferenceModal from '../documents/values/modals/document_reference.vue'
import ConfirmationModal from '../components/modals/confirmation.vue'

import ValueList from './value_list.vue'
import AppraisalAreas from './appraisal_areas.vue'
import Parent from './parent.vue'
import TwoColumnTables from './two_column_tables.vue'
import ReflectiveQuestions from './reflective_questions.vue'
import OldReflectiveQuestions from './old_reflective_questions.vue'

import SilverdaleNormalSchool from './silverdale_normal_school'
import ManurewaIntermediate from './manurewa_intermediate'
import ProfessionalGrowthCycle from './professional_growth_cycle'

export default {
    ValueModal,
    DocumentReferenceModal,
    ConfirmationModal,
    ValueList,
    AppraisalAreas,
    Parent,
    TwoColumnTables,
    ReflectiveQuestions,
    OldReflectiveQuestions,
    ...ProfessionalGrowthCycle,
    ...ManurewaIntermediate,
    ...SilverdaleNormalSchool
}
