import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox']
  static values = { path: String }

  connect() {
    const controller = this

    $(this.checkboxTarget).checkbox({
      onChange() {
        $.post(controller.pathValue, {
          membership: {
            administrator: this.checked
          },
          _method: 'patch'
        }).always(() => {
          controller.element.classList.toggle('memberships__membership--administrator', this.checked)
        })
      }
    })
  }

  disconnect() {
    $(this.checkboxTarget).checkbox('destroy')
  }
}
