import Store from '../../store'
import ValueCoordinator from './value_coordinator'
import EditValueCoordinator from './edit_value_coordinator'

export default class AddValueCoordinator extends ValueCoordinator {
  constructor(shortIdentifier, { identifier, parentId, templateConfigurationValueId, type }) {
    super(shortIdentifier)

    if (type === 'Mixed') {
      Store.dispatch('createValue', {
        identifier: identifier,
        parent_id: parentId,
        template_configuration_value_id: templateConfigurationValueId,
        type: 'Value::Mixed'
      }).then(value => {
        new EditValueCoordinator(shortIdentifier, {
          header: `Add ${shortIdentifier}`,
          value
        })
      })
    } else {
      this.identifier = identifier
      this.parentId = parentId
      this.templateConfigurationValueId = templateConfigurationValueId
      this.type = type

      this.newValue().then(this.establishModal)
    }
  }

  setHeader() {
    this.modal.find('.header').text(`Add ${this.shortIdentifier}`)
    this.modal.find('.actions .positive.button').text('Add')
  }

  newValue() {
    return $.get(`${this.documentUrl}/values/new`, { 'value[type]': `Value::${this.type}` })
  }

  setAdditionalFormData(formData) {
    formData.append('value[identifier]', this.identifier)
    if (this.parentId) { formData.append('value[parent_id]', this.parentId) }
    if (this.templateConfigurationValueId) { formData.append('value[template_configuration_value_id]', this.templateConfigurationValueId) }

    return formData
  }

  updatePage(content) {
    Store.commit('createValue', content)
  }
}
