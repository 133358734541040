const defaultState = () => ({
  isVisible: false,
  referenceId: null,
  forceMinimum: true,
  identifiers: null,
  persistReference() {}
})

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    show(state, payload) {
      Object.assign(state, payload)
      state.isVisible = true
    },
    approve(state, { identifiers }) {
      state.persistReference(identifiers)
      .then(() => {
        state.isVisible = false
      }).catch(error => {
        console.log(error.message)
        alert('Something went wrong, please try again.')
      })
    },
    deny(state) {
      state.isVisible = false
    },
    reset(state) {
      Object.assign(state, defaultState())
    }
  }
}
