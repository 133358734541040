import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['prompt']
  static values = { path: String }

  connect() {
    $(this.element).search({
      apiSettings: {
        url: `${this.pathValue}/search_users?q={query}`
      },
      fields: {
        title: 'name'
      },
      onSelect: (result) => {
        this.element.classList.add('loading')

        $.post(this.pathValue, {
          membership: {
            user_id: result.id
          }
        }).always(function() {
          Turbolinks.visit(this.pathValue, { action: 'replace' })
        })
      }
    })
  }

  disconnect() {
    $(this.element).search('destroy')
  }

  focusPrompt() {
    this.promptTarget.focus()
  }
}
