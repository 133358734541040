import Store from '../../store'
import ValueCoordinator from './value_coordinator'

export default class EditValueCoordinator extends ValueCoordinator {
  constructor(shortIdentifier, { value, header }) {
    super(shortIdentifier)

    if (!header) { header = `Edit ${shortIdentifier}` }

    this.id = value.id
    this.header = header

    this.editValue().then(this.establishModal)
  }

  setHeader() {
    this.modal.find('.header').text(this.header)
    this.modal.find('.actions .positive.button').text('Save')
  }

  editValue() {
    return $.get(`${this.documentUrl}/values/${this.id}/edit`)
  }

  updatePage(content) {
    Store.commit('updateValue', content)
  }
}
