export default class CommentsCoordinator {
  constructor(component) {
    this.visualiseContent = this.visualiseContent.bind(this)
    this.registerVisibility = this.registerVisibility.bind(this)
    this.subscribe = this.subscribe.bind(this)
    this.unsubscribe = this.unsubscribe.bind(this)
    this.disableForm = this.disableForm.bind(this)
    this.enableForm = this.enableForm.bind(this)
    this.component = component
    this.value = this.component.value
    this.document_url = this.component.$store.state.documentUrl
    this.comments_url = `${this.document_url}/values/${this.value.id}/comments`

    this.modal = $('.ui.commenting.modal')
    this.content = this.modal.find('> .content')
    this.form = this.modal.find('> .actions form')
    this.comment_content = this.form.find('#comment_content')

    this.subscribe_button = this.modal.find('.subscribe.button')
    this.unsubscribe_button = this.modal.find('.unsubscribe.button')

    this.approve_button = this.modal.find('.actions .positive.button')
  }

  render() {
    this.mountSubscriptionButtons()

    // Hooks into the Destroy urls and reinitialises the content area on success
    this.content.on('ajax:success', '.actions a.destroy', event => {
      const [response] = event.detail
      this.initialiseContent(response)
      this.updateCommentStatistics(response.commentStatistics)
    })

    $.get(this.comments_url)
    .done(data => {
      this.initialiseContent(data)

      this.modal.modal({
        selector: {
          approve: '.actions .positive:not(.disabled)',
          deny: '.header .negative'
        },
        closable: false,
        context: '.modal.context',
        observeChanges: true,
        onVisible: this.visualiseContent,
        onHide: () => {
          this.unmountSubscriptionButtons()

          const pusher_height = $('.top.spacer').height() + $('.small.top.spacer').height()
          this.component.$el.scrollIntoView()
          window.scrollBy(0, -pusher_height)

          if (/^#values_[0-9]+_comments$/.exec(window.location.hash)) {
            history.pushState('', document.title, window.location.pathname + window.location.search)
          }
        },
        onHidden: () => {
          $(this.component.$el).transition({ animation: 'pulse' })
        },
        onApprove: () => {
          if (this.comment_content.val() === '') {
            this.comment_content.trigger('focus')
            return false
          }

          $.ajax({
            url: this.comments_url,
            type: 'POST',
            data: this.form.serialize(),
            beforeSend: this.disableForm,
            complete: this.enableForm
          }).then(content => {
            this.initialiseContent(content)
            this.updateCommentStatistics(content.commentStatistics)
            this.visualiseContent()
          }).catch(() => {
            alert('An error has occurred and your comment was not added. Please try again.')
            this.comment_content.trigger('focus')
          })

          return false
        },
        onDeny: () => {
          if (this.comment_content.val() !== '') {
            if (!confirm('Are you sure you want to abandon your comment?')) {
              this.comment_content.trigger('focus')
              return false
            }
          }
        }
      }).modal('show')
    })
  }

  initialiseContent(data) {
    this.updateCommentStatistics(data.commentStatistics)
    this.content.html(data.comments_html)
    this.comment_content.val('').trigger('focus')

    this.toggleSubscription(data.isSubscribed)
  }

  visualiseContent() {
    this.content.animate({ scrollTop: this.content.prop('scrollHeight') }, {
      duration: 1000,
      complete: this.registerVisibility
    })
  }

  registerVisibility() {
    const self = this

    this.content.find('.comment').has('.metadata > span.new').visibility({
      context: this.content,
      onTopVisible() {
        self.readComment(this)
      }
    })
  }

  readComment(comment) {
    $.get(`${this.comments_url}/${$(comment).data('id')}`)
    .then(() => {
      this.component.$store.commit('decrementUnreadComments', this.value.id)
    })
  }

  updateCommentStatistics(commentStatistics) {
    this.component.$store.commit('updateCommentStatistics', {
      value_id: this.value.id,
      comment_statistics: commentStatistics
    })
  }

  mountSubscriptionButtons() {
    this.modal.on('click', '.subscribe.button', this.subscribe)
    this.modal.on('click', '.unsubscribe.button', this.unsubscribe)
  }

  unmountSubscriptionButtons() {
    this.modal.off('click', '.subscribe.button', this.subscribe)
    this.modal.off('click', '.unsubscribe.button', this.unsubscribe)
  }

  subscribe() {
    $.post(`${this.comments_url}/subscribe`, { _method: 'patch' }).done(() => {
      this.toggleSubscription(true)
    })
  }

  unsubscribe() {
    $.post(`${this.comments_url}/unsubscribe`, { _method: 'patch' }).done(() => {
      this.toggleSubscription(false)
    })
  }

  toggleSubscription(isSubscribed) {
    this.subscribe_button.toggle(!isSubscribed)
    this.unsubscribe_button.toggle(isSubscribed)
  }

  disableForm() {
    this.approve_button.addClass('disabled')
  }

  enableForm() {
    this.approve_button.removeClass('disabled')
  }
}
