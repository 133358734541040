<template>
  <div class='ui segment' v-bind:class='value.colour' v-bind:id='valueId'>
    <i class='fitted inverted grey ellipsis horizontal handle icon'></i>
    <a @click='open_comments_modal' class='ui orange floating unread comment label' title='Comments' v-if='unreadComments'>
      <i class='small comment icon'></i>
      <span class='count'>{{ unreadComments }}</span>
    </a>
    <div class='ui top right pointing value dropdown'>
      <i class='fitted grey write icon' title='Actions'></i>
      <div class='menu'>
        <div @click='open_edit_modal' class='item' v-if='editable'>
          <i class='edit icon'></i>
          Edit
        </div>
        <div @click='destroy' class='item' v-if='destroyable'>
          <i class='delete icon'></i>
          Destroy
        </div>
        <div @click='open_comments_modal' class='item comments' v-if='commentable'>
          <i class='comments icon'></i>
          Comments
          <span class='count' v-if='totalComments'>{{ totalComments }}</span>
        </div>
        <div class='item colour'>
          <i class='paint brush icon'></i>
          Colour
          <div class='ui icon left menu'>
            <a @click='set_colour("red")' class='item' title='Red'>
              <i class='red circle fitted icon'></i>
            </a>
            <a @click='set_colour("orange")' class='item' title='Orange'>
              <i class='orange circle fitted icon'></i>
            </a>
            <a @click='set_colour("yellow")' class='item' title='Yellow'>
              <i class='yellow circle fitted icon'></i>
            </a>
            <br>
            <a @click='set_colour("olive")' class='item' title='Olive'>
              <i class='olive circle fitted icon'></i>
            </a>
            <a @click='set_colour("green")' class='item' title='Green'>
              <i class='green circle fitted icon'></i>
            </a>
            <a @click='set_colour("teal")' class='item' title='Teal'>
              <i class='teal circle fitted icon'></i>
            </a>
            <br>
            <a @click='set_colour("blue")' class='item' title='Blue'>
              <i class='blue circle fitted icon'></i>
            </a>
            <a @click='set_colour("violet")' class='item' title='Violet'>
              <i class='violet circle fitted icon'></i>
            </a>
            <a @click='set_colour("purple")' class='item' title='Purple'>
              <i class='purple circle fitted icon'></i>
            </a>
            <br>
            <a @click='set_colour("pink")' class='item' title='Pink'>
              <i class='pink circle fitted icon'></i>
            </a>
            <a @click='set_colour("brown")' class='item' title='Brown'>
              <i class='brown circle fitted icon'></i>
            </a>
            <a @click='set_colour("grey")' class='item' title='Grey'>
              <i class='grey circle fitted icon'></i>
            </a>
            <br>
            <a @click='set_colour("black")' class='item' title='Black'>
              <i class='black circle fitted icon'></i>
            </a>
            <a @click='set_colour(nil)' class='item' title='None'>
              <i class='red ban fitted icon'></i>
            </a>
          </div>
        </div>
        <div @click='open_reference_modal' class='item' v-if='referenceable'>
          <i class='star icon'></i>
          References
          <span class='count' v-if='totalReferences'>{{ totalReferences }}</span>
        </div>
        <div @click='find_referencer' class='item' v-if='this.value.type === "Reference"'>
          <i class='crosshairs icon'></i>
          Find Referencer
        </div>
        <div class='blue header' v-bind:title='value.updated_at'>
          <i class='clock icon'></i>
          Updated: {{ formatted_date(value.updated_at) }}
        </div>
      </div>
    </div>
    <component v-bind='value.component'></component>
  </div>
</template>

<script>
  import VueScrollTo from 'vue-scrollto'

  import DateFormatter from '../date_formatter'
  import EditValueCoordinator from '../documents/values/edit_value_coordinator'
  import CommentsCoordinator from '../documents/values/comments_coordinator'
  import ColourChooser from '../documents/values/colour_chooser'
  import ValueDestroyer from '../documents/values/value_destroyer'

  import TextValue from './values/text.vue'
  import ImageValue from './values/image.vue'
  import LinkValue from './values/link.vue'
  import FileValue from './values/file.vue'
  import DriveValue from './values/drive.vue'
  import MixedValue from './values/mixed.vue'
  import ReferenceValue from './values/reference.vue'

  export default {
    data() {
      return {
        uneditableTypes: ['Reference'],
        undestroyableTypes: ['Reference'],
        unreferenceableTypes: ['Reference']
      }
    },
    props: {
      value: Object,
      shortIdentifier: String,
      commentable: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      valueId() {
        return `value-${this.value.id}`
      },
      totalComments() {
        return this.$store.getters.totalComments(this.value.id)
      },
      unreadComments() {
        return this.$store.getters.unreadComments(this.value.id)
      },
      totalReferences() {
        return this.$store.getters.totalReferences(this.value.reference_ids[0])
      },
      editable() {
        return !this.uneditableTypes.includes(this.value.type)
      },
      destroyable() {
        return !this.undestroyableTypes.includes(this.value.type)
      },
      referenceable() {
        return this.$store.getters.supportsReferences &&
        !this.unreferenceableTypes.includes(this.value.type)
      }
    },
    methods: {
      formatted_date(date) {
        return DateFormatter.format(new Date(date))
      },
      open_edit_modal() {
        new EditValueCoordinator(this.shortIdentifier, { value: this.value })
      },
      open_comments_modal() {
        new CommentsCoordinator(this).render()
      },
      set_colour(colour) {
        new ColourChooser(this).setColour(colour)
      },
      destroy() {
        new ValueDestroyer(this).destroyValue()
      },
      open_reference_modal() {
        this.$store.commit('documentReferenceModal/show', {
          referenceId: this.value.reference_ids[0] || null,
          forceMinimum: false,
          persistReference: (identifiers) => {
            return this.$store.dispatch('setReference', {
              value: this.value,
              identifiers
            })
          }
        })
      },
      find_referencer() {
        const referencer = this.$store.state.values[this.value.component.reference_value_id]

        VueScrollTo.scrollTo(`#value-${referencer.id}`, 500, {
          offset: -100,
          onDone(element) {
            $(element).transition({animation: 'pulse'})
          }
        }
        )
      }
    },
    mounted() {
      $(this.$el).find('.ui.dropdown').dropdown({ action: 'hide' })

      if (this.$store.state.commentsValueId === this.value.id) {
        this.open_comments_modal()
      }
    },
    components: {
      TextValue,
      ImageValue,
      LinkValue,
      FileValue,
      DriveValue,
      MixedValue,
      ReferenceValue
    }
  }
</script>
