<template>
  <div class='file browser field'>
    <div class='ui fluid action top attached input'>
      <input :value='fileName' disabled name='name' placeholder='Browse...' type='text'>
      <div @click='browseDevice' class='ui right icon blue browse button' data-inverted data-tooltip='Browse Device'>
        <i class='folder open icon'></i>
      </div>
      <div :disabled='!pickerApiLoaded || !clientApiLoaded' @click='browseGoogleDrive' class='ui right icon yellow drive button' data-inverted data-tooltip='Browse Google Drive'>
        <i class='google drive icon'></i>
      </div>
    </div>
    <div class='ui bottom attached progress'>
      <div class='bar'></div>
    </div>
    <input @change='assignBrowsedFile' name='value[file]' ref='fileInput' type='file'>
  </div>
</template>

<script>
  export default {
    props: {
      existingName: String,
      viewId: {
        validator(value) {
          return ['DOCS', 'DOCS_IMAGES', 'DOCS_VIDEOS'].includes(value)
        }
      }
    },
    data() {
      return {
        client: null,
        fileName: null,
        picked: {},
        pickerApiLoaded: false,
        clientApiLoaded: false,
        oauthToken: null,
        config: {
          clientId: '313527239949-nmurf7c1rr96aac0jn09mmofeo0hovd5.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/drive.file',
          developerKey: 'AIzaSyAu4kLPDeVLakQ2Zgcbrs56wwKkBK8ORdA',
          discoveryDoc: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
          appId: '313527239949'
        }
      }
    },
    created() {
      this.fileName = this.existingName
    },
    mounted() {
      this.client = google.accounts.oauth2.initTokenClient({
        client_id: this.config.clientId,
        scope: this.config.scope,
        callback: this.handleAuthResult,
        prompt: ''
      })

      gapi.load('client', () => {
        gapi.client.init({
          apiKey: this.config.developerKey,
          discoveryDocs: [this.config.discoveryDoc],
        }).then(() => {
          this.clientApiLoaded = true
        })
      })

      gapi.load('picker', () => {
        this.pickerApiLoaded = true
      })
    },
    methods: {
      browseGoogleDrive() {
        this.client.requestAccessToken()
      },
      handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
          this.oauthToken = authResult.access_token
          this.createPicker()
        }
      },
      createPicker() {
        if (this.pickerApiLoaded && this.clientApiLoaded && this.oauthToken) {
          const picker = new google.picker.PickerBuilder()
            .addView(google.picker.ViewId[this.viewId])
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .setOAuthToken(this.oauthToken)
            .setDeveloperKey(this.config.developerKey)
            .setAppId(this.config.appId)
            .setCallback(this.pickerCallback)
            .build()

          picker.setVisible(true)
        }
      },
      pickerCallback(data) {
        if (data[google.picker.Response.ACTION] === "loaded") {
          this.$store.commit('setValueModalReady', false)
        } else if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
          const properties = data[google.picker.Response.DOCUMENTS][0]

          gapi.client.drive.files.get({ fileId: properties[google.picker.Document.ID], alt: 'media', supportsAllDrives: true })
          .then((request) => {
            const length = request.body.length
            const bytes = new Uint8Array(length)

            for (let i = 0; i < length; i++) {
              bytes[i] = request.body.charCodeAt(i)
            }

            const blob = new Blob([bytes], { type: properties.mimeType })
            const file = new File([blob], properties.name, { type: properties.mimeType })
            const dataTransfer = new DataTransfer()

            dataTransfer.items.add(file)
            this.$refs.fileInput.files = dataTransfer.files
            this.fileName = properties.name
            this.$store.commit('setValueModalReady', true)
          })
        } else if (data[google.picker.Response.ACTION] === google.picker.Action.CANCEL) {
          this.$store.commit('setValueModalReady', true)
        }
      },
      browseDevice() {
        this.$refs.fileInput.click()
      },
      assignBrowsedFile() {
        const file = this.$refs.fileInput.files[0]

        this.fileName = file.name
      }
    }
  }
</script>
